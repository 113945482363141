import moment from "moment";

export function formatNums(num, sep, decimal = 2, currency = 'EUR ') {
    sep = sep || "'";
    return currency + String(num.toFixed(decimal)).replace(/\.\d+|\d(?=(?:\d{3})+(?!\d))/g,
        function(a) {
            return a.length === 1 ? a + sep : a;
        }
    );
}

export function isInteger(str) {
    return /^([1-9]\d*)$/.test(str) && Number(str) <= 2147483647 // postgres max int
}

export const personalInfoModel = (investorInfo) => {
    const user = investorInfo?.user;
    let dob = moment(user?.date_of_birth);
    if (dob.isValid()) {
        dob = dob.format('DD.MM.YYYY');
    } else {
        dob = '';
    }
    return {
        profileImage: user?.profile_image,
        salutation: user?.salutation,
        name: user?.first_name,
        surname: user?.last_name,
        dateOfBirth: dob,
        nationality: user?.nationality_id,
        prefix: user?.prefix,
        phoneNumber: user?.phone_number
    }
}

export const addressDetailModel = (investorInfo) => {
    return {
        street: investorInfo?.street || '',
        houseNumber: investorInfo?.house_number || '',
        city: investorInfo?.city || '',
        zipCode: investorInfo?.zip || '',
        country: investorInfo?.country_id || '',
    }
}

export const loginDetailModel = (investorInfo) => {
    return {
        email: investorInfo?.user?.email
    }
}

export const formatDateTime = (date, format = 'DD.MM.YYYY') => {
    return moment(date).format(format)
}

export const getFullUrl = (uri) => {
    return document.domain + uri
}

export const capitalize = (str) => {
    return str.split(' ').map(str => str.charAt(0).toUpperCase() + str.slice(1)).join(' ');
}
